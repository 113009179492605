<template>
  <game></game>
</template>

<script>
import Game from "./components/Game";

export default {
  name: 'App',
  components: {
    Game
  }
}
</script>

<style></style>
